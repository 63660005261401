import React from 'react'
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
    <div className='home'>
          <div id="wrapper">
            <div id="NotFound">
                <div id="message404">Error 404</div>
                <p>Something when wrong, we couldn't find what you were looking for.</p>
                <p><Link to="/">Let's start Again?</Link></p>
            </div>
          </div>
      </div>
    )

}

export default NotFound;
