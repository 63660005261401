import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <div>
            <section id="banner" className="major">
                <div className="inner">
                <div className="img-profile">
                    <img className="img-responsive imagen" src="//d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/profileSite.png" alt=""/>
                </div>
                <div className="header-details">
                <header className="major">
                    <h1>Jose Lara</h1>
                    </header>
                    <div className="content">
                    <p>Experienced Software Engineer, <br/>Automation Software, Full Stack development<br/> & DevOps enthusiastic.</p>
                    <ul className="actions">
                        <li><Link to="/portfolio" className="button next scrolly">see my work</Link></li>
                    </ul>
                    </div>
                </div>
                </div>
            </section>
        </div>
    )
    
};


export default Header;