import React,{ Component} from 'react';
import { Link } from "react-router-dom";
// import portfolioDetail from './portfolioDetailComponent'

class Portfolio extends Component {
    constructor (props) {
        super(props);
        this.state = {
        }
        this.loopData = this.loopData.bind(this);

      }
      componentDidMount() {
        var reverse = this.props.portfolioData.sort((a, b) => {
          return b.id - a.id
        })
        this.setState({
          portfolioData: reverse
        })
      }
      loopData() {
        return this.props.portfolioData.map((data) => {
          return(
            <article  className="col-sm-12 col-sm-6 col-lg-6 portfolio-container"  key={data.id}>
                <div className="text-center portfolio-item row-eq-height">
                  <h3>{data.title}</h3>
                  <div className="rounded mx-auto d-block">
                      <Link to={`/portfolio/${data.id}`}><img src={data.img} alt="" className="portfolio-img"/></Link>
                  </div>
                  <p className="portfolio-description">{data.description}</p>
                  <ul className="list-inline item-details">
                    <li className="list-inline-item">Client: <span>{data.client}</span></li>
                    <li className="list-inline-item">Date: <span> {data.date}</span></li>
                    <li className="list-inline-item">Service: <span> {data.service} </span></li>
                  </ul>
                </div>
            </article>
          )
        });
      }
      render(){
          return(
            <section id="portfolio">
              <div className="inner">
                <div className="row">
                  <header className="col-sm-12">
                    <h1 className="major text-center">Portfolio</h1>
                  </header>
                </div>
                <div className="row">
                  {this.loopData()}
                </div>
              </div>
            </section>
          )
        }          
}

export default Portfolio;