import React, { Component} from 'react';
import axios from 'axios';

class Contact extends Component {
  constructor () {
    super()
    this.state = {
      name: '',
      phone: '',
      email: '',
      message: '',
      feedbackMessage: ''
    }
  }
   
  handleNameChange = event => {
    var pattern = /\S+/;
    if(pattern.test(event.target.value) && event.target.value.length < 3){
      document.getElementById('feedbackMessage').classList.remove(("alert-success"))
      document.getElementById('feedbackMessage').classList.add("alert-danger")
      this.setState({feedbackMessage: "Name must at least 3 characters long!"})

    }
    else{
      document.getElementById('feedbackMessage').classList.remove("alert-danger")
      this.setState({feedbackMessage: ""})
      this.setState({ name: event.target.value })
    }
    
  }

  handlePhoneChange = event => {
    var pattern = /^\d+$/;
    if(!pattern.test(event.target.value)){
      document.getElementById('feedbackMessage').classList.remove(("alert-success"))
      document.getElementById('feedbackMessage').classList.add("alert-danger")
      this.setState({feedbackMessage: "Phone Must be Digits only!"})

    }
    else if(event.target.value.length !== 10){
      document.getElementById('feedbackMessage').classList.remove(("alert-success"))
      document.getElementById('feedbackMessage').classList.add("alert-danger")
      this.setState({feedbackMessage: "Phone Must be 10 Digits long!"})

    }
    else{
      document.getElementById('feedbackMessage').classList.remove("alert-danger")
      this.setState({feedbackMessage: ""})
      this.setState({ phone: event.target.value })
    }
  }

  handleEmailChange = event => {
    // eslint-disable-next-line
    var pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if(!pattern.test(event.target.value) || event.target.value.length < 3){
      document.getElementById('feedbackMessage').classList.remove(("alert-success"))
      document.getElementById('feedbackMessage').classList.add("alert-danger")
      this.setState({feedbackMessage: "Email is not valid"})
    }
    else{
      document.getElementById('feedbackMessage').classList.remove("alert-danger")
      this.setState({feedbackMessage: ""})
      this.setState({ name: event.target.value })
    }
    this.setState({ email: event.target.value })}

  handleMessageChange = event => {this.setState({ message: event.target.value })}


  handleClear = event => {
    document.getElementById('feedbackMessage').classList.remove(("alert-success"))
    document.getElementById('feedbackMessage').classList.remove("alert-danger")

    this.setState({
      name: '',
      phone: '',
      email: '',
      message: '',
      feedbackMessage: '',
    })
  }

  handleSubmit = event => {
    event.preventDefault();
    if(this.state.name === '' || this.state.email === '' || this.state.phone === '' || this.state.message === ''){
      document.getElementById('feedbackMessage').classList.remove(("alert-success"))
      document.getElementById('feedbackMessage').classList.add("alert-danger")
      this.setState({feedbackMessage: "This form is incomplete or invalid"})
      return;
    }
    else{
      document.getElementById('feedbackMessage').classList.remove("alert-danger")
      this.setState({feedbackMessage: ""})

      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

      axios.post('https://joselara.herokuapp.com/api/contact/message',
          {
            headers: { 'crossDomain': true, 'Content-Type': 'application/json'},
            name: this.state.name, 
            phone: this.state.phone, 
            email: this.state.email, 
            message: this.state.message
          }
      )
      .then(res => {
        document.getElementById('feedbackMessage').classList.remove("alert-danger")
        document.getElementById('feedbackMessage').classList.add("alert-success")
        this.setState({
          feedbackMessage: "Message has been sent!",
        })
      })
      .catch(error => {
        document.getElementById('feedbackMessage').classList.remove("alert-success")
        document.getElementById('feedbackMessage').classList.add("alert-danger")
        this.setState({
          feedbackMessage: 'There was an Error please double check the data, or try again later!',
        })
      })
    }
  }
  render() {
      return(
        <section className="contact">
        <div className="inner">     
          <h1 className="text-center">Contact me</h1>
            <form  onSubmit={this.handleSubmit}>
              <div className="fields">
                <div className="field half">
                  <label htmlFor="name">Name</label>
                  <input type="text" name="this.state.name" id="name" onChange={this.handleNameChange}/>
                </div>
                <div className="field half">
                  <label htmlFor="phone">Phone</label>
                  <input type="text" name="this.state.phone" id="phone" onChange={this.handlePhoneChange}/>
                </div>
                <div className="field">
                  <label htmlFor="email">Email</label>
                  <input type="text" name="this.state.email" id="email" onChange={this.handleEmailChange}/>
                </div>
                <div className="field">
                  <label htmlFor="message">Message</label>
                  <textarea name="this.state.message" id="message" rows="6" onChange={this.handleMessageChange}></textarea>
                </div>
                <div id="success">
                  <div id="feedbackMessage" className='alert'><strong>{this.state.feedbackMessage}</strong></div>
                </div>
              </div>
              <ul className="actions">
                <li><input type="submit" value="Send Message" className="primary"/></li>
                <li><input type="reset" value="Clear" onClick={this.handleClear} /></li>
              </ul>
            </form>
        </div>
      </section>
    );
  }
}

export default Contact;