import React from 'react';

const Footer = () => {
	return (
		<footer id='footer'>
			<div className='inner'>
				<ul className='icons'>
					<li>
						<a
							rel='noopener noreferrer'
							href='https://www.linkedin.com/in/joselara02'
							target='_blank'
							className='icon'>
							<i className='icon alt fa-linkedin' />
						</a>
					</li>
					<li>
						<a
							rel='noopener noreferrer'
							href='https://blog.joselara.dev'
							target='_blank'
							className='icon'>
							<i className='icon alt fa-medium' />
						</a>
					</li>
					<li>
						<a
							rel='noopener noreferrer'
							href='https://github.com/realjoselara'
							target='_blank'
							className='icon'>
							<i className='icon alt fa-github' />
						</a>
					</li>
					<li>
						<a
							rel='noopener noreferrer'
							href='https://www.youtube.com/channel/UCF_OlDQQX3Mv9ybSqESaUxg'
							target='_blank'
							className='icon'>
							<i className='icon alt fa-youtube' />
						</a>
					</li>
					<li>
						<a
							rel='noopener noreferrer'
							href='mailto: contact@joselara.dev'
							className='icon'>
							<i className='icon alt fa-envelope' />
						</a>
					</li>
				</ul>
				<ul className='copyright'>
					<li>
						&copy;{' '}
						<a rel='noopener noreferrer' href='https://www.twitter.com/realjoselara' target='_blank'>
							Jose Lara
						</a>
					</li>
					<li>
						<a rel='noopener noreferrer' href='https://www.twitter.com/realjoselara' target='_blank'>
							Made with React
						</a>
					</li>
				</ul>
			</div>
		</footer>
	);
};

export default Footer;
