import React from 'react';

const About = () => {
    return(
        <section id="about">
            <div className="inner">
                <header className="">
                    <h1 className="text-center">About Me</h1>
                </header>
                    {/* <p>I'm a software engineer, holding a MS in Software Engineering from Stevens Institute of Technology, and BS in Computer Science from Regis University. I take every opportunity to learn something new. I work with Automation Software Development, Full stack Development, and IoT. Some of the stack I've used: </p> */}
                    <p>Experienced Software Engineer building Data-driven Full Stack applications with a demonstrated history for primarily working in the financial services industry. Interest in Full Stack Development, Machine Learning, Everything Data, IoT and DevOps. Skill in Python, JavaScript, C#, ASP.net core, Cloud services, Microservice architecture. I take every opportunity to learn something new. I work with Automation Software Development, and Full Stack Development.</p>
                    <p>Some of the Tech I've used (currently or in the past): </p> 
                <ul className="techList">
                    <li>Python (Flask, Django, SQLAlchemy, PyMongo)</li>
                    <li>Javascript (NodeJS, Express, MongoDB, React, Redux, jQuery, AngularJS, Gulp, Webpack)</li>
                    <li>.NET (C#, ASP.NET Core, Assembly, Entity Framework Core)</li>
                    <li>Java (Spring Boot, Spring Data)</li>
                    <li>Cloud Services, worked with Azure (VMs, WebApp, ADF, Azure Database, Blob Storage) and AWS (EC2, Lightsail, RDS, S3).</li>
                    <li>Exposure to Infrastructure as code with terraform and Ansible.</li>
                </ul>
                <ul className="actions" id="github-links" >
                <li><a rel="noreferrer noopener" href="https://blog.joselara.dev" target="_blank" className="button next">Check my Blog</a></li>
                    <li><a rel="noreferrer noopener" href="https://github.com/realjoselara" target="_blank" className="button next">Check my GitHub</a></li>
                </ul>
            </div>
        </section>
        )
}

export default About;