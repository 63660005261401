var portfolioData = [
  // {
  //   id: 0,
  //   client: 'Personal Project',
  //   title: 'Pokemon Fight Game',
  //   date: 'Jan 2017',
  //   service: 'Web Development',
  //   description: 'A simple Pokemon Game that utilizes the rock, paper, Scissors concept with Pokemons Figthing each other.',
  //   img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/pokemon-game.png',
  //   url: 'https://realjoselara.github.io/Pokemon-Game/',
  //   tech: ["Javascript", "HTML", "CSS"]

  // },
  // {    
  //   id: 1,
  //   client: 'World News Headlines',
  //   title: 'World News Headlines',
  //   date: 'N/A',
  //   service: 'Python Development',
  //   description: 'Web scrapping tool built using python, that searches on for content on CNN, IOL, BBC, LISTIN DIARIO, & DIARIO LIBRE',
  //   img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/worldHeadlines.png',
  //   url: 'http://headlines.joselara.me/'

  // },
  // {
  //   id: 2,
  //   client: 'Personal Project',
  //   title: 'Social Media Clone',
  //   date: 'April 2018',
  //   service: 'Web Development',
  //   description: 'Social Media Clone. This responsive social media facebook clone. The intention is to practice with CSS and Elements positioning.',
  //   img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/social-club-mobile.png',
  //   url: 'http://realjoselara.github.io/SocialMediaClone/',
  //   tech: ["HTML5", "CSS3", "Javascript", "JQuery"]

  // },
  // {
  //   id: 3,
  //   client: 'Personal Project',
  //   title: 'Gif Search Engine',
  //   date: 'Jan 2017',
  //   service: 'Web Development',
  //   description: 'This is a GIF search engine project that was developed to call the Giphy.com API. This API returns a JSON file, JavaScript is used to clean the data and render to browser.',
  //   img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/gif_img1.png',
  //   url: 'http://larajose.com/gif',
  //   tech: ["HTML", "CSS", "JavaScript", "Bootstrap 4", "jQuery", "AJAX"]

  // },
  // {
  //   id: 4,
  //   client: 'Personal Project',
  //   title: 'Music Player',
  //   date: 'Jan 2017',
  //   service: 'Web Development',
  //   description: 'Users can search for artist names or song names. This site uses to practice calls to external APIs. It calls the soundcloud API service and retrieved the data to display it to users.',
  //   img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/musicPlayerV2.png',
  //   url: 'http://larajose.com/musicplayer', 
  //   tech: ["HTML5", "CSS3", "Javascript", "Bootstrap 3", "JQuery", "Git"]

  // },
  {
    id: 5,
    client: 'NYC Int. Auto Transport',
    title: 'NYC Int. Auto Transport',
    date: 'April 2016',
    service: 'Web Development',
    description: 'Provided web services to NYC International Auto Transport, a leading auto transportation company.',
    img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/nyc_new.png',
    url: 'http://nycinternationaltransport.net/',
    tech: ["HTML5", "CSS3", "JavaScript", "Bootstrap 3", "Python", "Django", "MySQL", "Git", "Heroku"]

  },
  {
    id: 6,
    client: 'Mujer Salud Vida',
    title: 'Mujer Salud Vida',
    date: 'Aug 2017',
    service: 'Web Development',
    description: 'This is a Full stack web application blog.',
    img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/MujerSaludVida.png',
    url: 'http://www.mujersaludvida.com/',
    tech: ["Django", "Python", "PostgreSQL", "AWS (S3, CloudFront)", "Heroku", "Bootstrap 3", "jQuery", "Javascript", "HTML", "CSS", "Git"]

  },
  // {
  //   id: 1,
  //   client: 'Principito Hangout Entertainment',
  //   title: 'Principito Hangout Entertainment',
  //   date: 'April 2018',
  //   service: 'Web Development',
  //   description: "Principito Presents / Principito Hangout Entertainment - New York & New Jersey's leading GAY Nightlife entrepreneur, event planner, promotions and entertainment.",
  //   img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/Principito.png',
  //   url: 'http://www.principitopresents.com',
  //   tech: ["HTML5", "SCSS", "JQuery", "Flask", "Python", "Heroku", "Git"]

  // },
  {
    id: 8,
    client: 'Personal Project',
    title: 'SafeMe',
    date: 'Sept 2018',
    service: 'Mobile Development',
    description: "SafeMe is a mobile application that will help users to notify other users, within a certain distance, that there's danger nearby in order to minimize casualty. This mobile application will alert nearby mobile devices.",
    img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/safemeapp.jpg',
    url: 'https://github.com/realjoselara/SafeMe',
    tech: ["Android SDK", "Java", "Firebase (Realtime database", "serveless functions (Written in NodeJS) and authentication)", "Git"]

  },
  {
    id: 9,
    client: 'LR Ley del Rap Feat. Mr. Paradise',
    title: 'LR Ley del Rap Feat. Mr. Paradise',
    date: 'Nov 2018',
    service: 'Web Development',
    description: "This Website was built to show case the release of new single named: Me supere from Dominican artists LR Ley del rap and Mr. Paradise.",
    img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/mesupere.png',
    url: 'http://www.mesupere.com',
    tech: ["React", "SCSS", "Flask", "Python", "Heroku", "Git"]

  },
  {
    id: 2,
    client: 'Personal Project',
    title: 'Find Me A Room',
    date: 'May 2019',
    service: 'Web Development',
    description: 'FindMeARoom is a Full Stack web application for students to post rental of rooms, where other students can find and connect with their potential roommates. This makes it easier for finding housing.',
    img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/findmearoom.png',
    url: 'https://www.findmearoom.net',
    tech: ["NodeJS", "Express", "MongoDB", "Azure Cloud Services", "jQuery", "handlebars", "CSS3", "Bootstrap 4", "Git"]

  },
  {
    id: 11,
    client: 'Santuario Music LLC',
    title: 'Santuario Music',
    date: 'Ongoing',
    service: 'Web Development',
    description: 'Santuario Music is a bilingual Media/News Full Stack Web application with primary focus on Music, Entertainment, and News.',
    img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/santuariomusic.png',
    url: 'https://www.santuariomusic.com',
    tech: ["NextJS", "ReactJS", "C# and ASP.NET Core for API", "Entity Framework Core", "AWS (S3, Cloudfront, EC2)", "PostgreSQL", "Redis (Cache)", "SCSS", "Bootstrap 4", "Git"]

  },
  {
    id: 12,
    client: 'Little Heaven Daycare',
    title: 'Little Heaven Daycare',
    date: 'June 2020',
    service: 'Web Development',
    description: 'Provided web services to Little Heaven Daycare, LLC. Little Heave is a quality childcare services provider, as well as a full day preschool program and after care program in Hackensack, New Jersey.',
    img: 'https://d3kopuxiy5nh4a.cloudfront.net/static/img/portfolio/littleheavendaycare.jpg',
    url: 'https://littleheavendaycare.org',
    tech: ["Node", "Express", "MongoDB", "Handlebars", "JavaScript", "AWS (S3, Cloudfront, EC2)", "Bootstrap 4", "SCSS", "Git"]

  },

]

export default portfolioData