import React from 'react';
import { Link } from 'react-router-dom';

const NavMenu = () => {
    return (
      <div>
          <header id="header" className="alt">
            <a href="/" className="logo"><strong>Jose Lara</strong> <span>Software & Web Developer</span></a>
            <nav>
                <a href="#menu">Menu</a>
            </nav>
          </header>
          <nav id="menu">
            <ul className="links">
              <li><Link to="/" className="button primary fit">Home</Link></li>
              <li><Link to="/portfolio" className="button primary fit">Portfolio</Link></li>
              <li><Link to="/about" className="button primary fit">About Me</Link></li>
              <li><Link to="/contact" className="button primary fit">Contact Me</Link></li>
              <li><a href="https://blog.joselara.dev" _target="blank" className="button primary fit">Blog</a></li>
              {/* <li><a href="#" className="button fit">Admin Log In</a></li> */}
            </ul>
          </nav>
      </div>
    )
};


export default NavMenu;