import React from 'react'
import Portfolio from './Portfolio';
import Home from './Home';
import NotFound from './NotFound';
import NavMenu from './NavMenu';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import {Switch, Route} from "react-router-dom";
import PortfolioDetail from './portfolioDetailComponent';
import portfolioData from "./../data/portfolioData"

const DetailWithId = ({match}) => {
  return(
      <PortfolioDetail portfolio={portfolioData.filter((item) => item.id === parseInt(match.params.id,10))[0]}
    />
  );
};

const Main = () => {
    return(
      <div className='home'>
        <div className="wrapper">
            <NavMenu />
              <Switch>
                  <Route path='/' component={()=> <Home portfolioData={portfolioData} />} exact />
                  <Route path='/portfolio' component={() => <Portfolio  portfolioData={portfolioData} />} exact />
                  <Route path='/portfolio/:id' component={DetailWithId} exact/>
                  <Route path='/about' component={About} exact />
                  <Route path='/contact' component={Contact} exact />
                  <Route path='/follow' component={Footer} exact />
                  <Route component={NotFound} />
              </Switch>
              <Footer />
        </div>
      </div>
    )
};

export default Main;

