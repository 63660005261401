import React from 'react';
import Portfolio from './Portfolio';
import Header from './Header';
import About from './About';

const Home = (props) => {
    return (<div className='home'>
          <div className="wrapper">
            <Header />
            <About />
            <div id="main">
                <Portfolio portfolioData={props.portfolioData} />
            </div>
          </div>
      </div>
    );
}

export default Home;
