import React from 'react';
import { Link } from 'react-router-dom';

const myStyle = {
	fontSize: '22px'
};

const PortfolioDetail = (props) => {
	return (
		<div id='main'>
			<section id='portfolio-detail'>
				<div className='inner'>
					<header>
						<h1>
							<a rel='noopener noreferrer' href={props.portfolio.url} target='_blank'>
								{' '}
								{props.portfolio.title}
							</a>
						</h1>
					</header>
					<div className='row'>
						<span className='col-md-6'>
							<a rel='noopener noreferrer' href={props.portfolio.url} target='_blank'>
								<img src={props.portfolio.img} alt='' className='rounded img-fluid' />
							</a>
						</span>
						<div className='col-md-6' style={myStyle}>
							<h3>About the project: </h3>
							<p>{props.portfolio.description}</p>
							{props.portfolio.tech ? `Tech Used:` : ''}
							{props.portfolio.tech ? (
								props.portfolio.tech.map((item, index) => (
									<li key={index} item={item}>
										{item}
									</li>
								))
							) : (
								''
							)}
						</div>
					</div>
					<div className='row mt-5'>
						<ul id='list-inline' className='col-sm-12'>
							<li className='list-inline-item' style={myStyle}>
								Client:<span> {props.portfolio.client}</span>
							</li>
							<li className='list-inline-item' style={myStyle}>
								Date:<span> {props.portfolio.date}</span>
							</li>
							<li className='list-inline-item' style={myStyle}>
								Service:<span> {props.portfolio.service} </span>
							</li>
						</ul>
					</div>
					<div className='link-to-site'>
						<a
							rel='noopener noreferrer'
							href={`${props.portfolio.url}`}
							className='button next'
							target='_blank'>
							See project Live
						</a>
					</div>
					<Link to='/portfolio' style={myStyle}>
						<i className='fa fa-arrow-left' aria-hidden='true'>
							{' '}
							Back Portfolio
						</i>
					</Link>
				</div>
			</section>
		</div>
	);
};

export default PortfolioDetail;
